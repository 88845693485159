import CryptoJS from 'crypto-js'

const privateKey = '98_3451K1d&DKd8&' //秘钥
export const privateKeyAndroid = '98_3451K1d&9Ud8&' //安卓秘钥
//AES ECB加密
export const encrypt = (word, keyStr) => {
  if (!word) return ''
  keyStr = keyStr || privateKey
  let key = CryptoJS.enc.Utf8.parse(keyStr) //秘钥
  let encryptedData = CryptoJS.AES.encrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  let encryptedBase64Str = encryptedData.toString().replace(/\//g, '_')
  encryptedBase64Str = encryptedBase64Str.replace(/\+/g, '-')
  return encryptedBase64Str
}
//AES ECB解密
export const decrypt = (word, keyStr) => {
  keyStr = keyStr || privateKey
  word = (word + '').replace(/\n*$/g, '').replace(/\n/g, '')
  let key = CryptoJS.enc.Utf8.parse(keyStr)
  let decryptedData = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  let decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData)
  return decryptedStr
}
